import React, { useEffect, useState } from "react";

import { Button } from '@material-ui/core';
import styled from 'styled-components';

import { config } from "./config";

import { login, logout } from "./utils/auth";

import {
  ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  ZOOM_REFRESH_TOKEN_LOCAL_STORAGE_KEY,
} from "./constant";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const User = () => {
  const [userData, setUserData] = useState({
    id: null,
    firstName: null,
    lastName: null,
    email: null,
  });

  useEffect(() => {
    if (localStorage.getItem(ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY)) {
      const accessToken = localStorage.getItem(ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY);

      fetch(`${config.api.baseUrl}/adapters/zoom/users/me`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          accessToken,
        }),
      })
        .then((res) => res.json())
        .then(({ success, data}) => {
          if (!success && data.message) {
            if (data.message === 'Invalid access token.') {
              logout();
            } else {
              login({
                token: JSON.stringify({
                  refreshToken: localStorage.getItem(ZOOM_REFRESH_TOKEN_LOCAL_STORAGE_KEY)
                }),
                isRefresh: true,
              });
            }

            return null;
          }

          setUserData({
            id: data.id,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
          })
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  if (userData.firstName) {
    return (
      <Content>
        <h3>Hello, {userData.firstName}.</h3>
        <Button variant="contained" onClick={logout}>Logout</Button>
      </Content>
    );
  }

  return null;
}

export default User;
